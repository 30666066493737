<template>
    <Form :questionList="questions" :formName="formName" />
</template>

<script>
/* eslint no-mixed-spaces-and-tabs: "off" */
import Form from '@/components/Form.vue'
import { QuestionModel, QuestionType, ChoiceOption } from '@ditdot-dev/vue-flow-form';
export default {
    name: 'Bonecas Amiguxas',
    components: {
	Form
    },
    data() {
	return {
	    formName: "bonecas-amiguxas",
            questions: [
		new QuestionModel({
		    id: 'email',
		    title: 'Olá, qual o seu e-mail?',
		    subtitle: 'O mesmo usado para acessar o curso.',
		    type: QuestionType.Email,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'name',
		    title: 'Seu nome é?',
		    type: QuestionType.Text,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'age',
		    title: 'E a sua idade?',
		    type: QuestionType.Number,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'goal_text',
		    title: 'Seja muito bem-vinda! Gostaria de te conhecer melhor. O que fez você adquirir o curso "Bonecas Amiguxas"?',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'objection_text',
		    title: 'Legal!\nComo artesã ou futura artesã, qual a sua maior dificuldade hoje?',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'multiple_choice_source',
		    title: 'Certo!\nE você conheceu nosso curso por onde?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    allowOther: true,
		    options: [
			new ChoiceOption({ label: 'Blog' }),
			new ChoiceOption({ label: 'Facebook' }),
			new ChoiceOption({ label: 'Instagram' }),
			new ChoiceOption({ label: 'Youtube' }),
			new ChoiceOption({ label: 'Indicação de um Amigo' }),
		    ]
		}),
		new QuestionModel({
		    id: 'level_text',
		    title: 'Maravilha!\nÚltima pergunta, como você considera o seu nível no crochê?',
		    subtitle: 'Descreva um pouco algo como: Já trabalho com amigurumis há x tempo, trabalho com crochê mas não amigurumis ainda, faço bordado, trico ou outra arte, etc)\n',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
            ]
	}
    }
}
</script>
