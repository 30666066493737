<template>
    <Form :questionList="questions" :formName="formName" />
</template>

<script>
/* eslint no-mixed-spaces-and-tabs: "off" */
import Form from '@/components/Form.vue'
import { QuestionModel , QuestionType,  ChoiceOption } from '@ditdot-dev/vue-flow-form';

export default {
    name: 'AE',
    components: {
	Form
    },
    data() {
	return {
	    formName: "android-express",
            questions: [
		new QuestionModel({
		    id: 'name',
		    // tagline: 'Hi! Welcome to our demo survey 😊',
		    title: 'Olá, qual o seu nome?',
		    type: QuestionType.Text,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'goal_text',
		    title: 'Seja muito bem-vindo! Gostaria de te conhecer melhor. Qual o seu objetivo em aprender a criar aplicativos?',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'why_me_text',
		    title: 'Legal!\nE o que fez escolher nosso treinamento?',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'multiple_choice_source',
		    title: 'Certo!\nE você conheceu nosso treinamento por onde?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    allowOther: true,
		    options: [
			new ChoiceOption({ label: 'Blog' }),
			new ChoiceOption({ label: 'Facebook' }),
			new ChoiceOption({ label: 'Instagram' }),
			new ChoiceOption({ label: 'Youtube' }),
			new ChoiceOption({ label: 'Indicação de um Amigo' }),
		    ]
		}),
		new QuestionModel({
		    id: 'level_text',
		    title: 'Maravilha!\nÚltima pergunta, como você considera o seu nível de programação?',
		    subtitle: 'Descreva um pouco até onde já aprendeu (sei apenas o que é IF ELSE, sei o básico de orientação a objetos, entendo classes e funções, já programo algumas coisas, já criei um aplicativo no passado, etc)\n',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
            ]
	}
    }
}
</script>
