import { createRouter, createWebHistory } from 'vue-router'
import MiddlewareStylesheet from "./middleware/stylesheet";

import Home from '../views/Home.vue'
import AE from '../views/AE.vue'
import IOS from '../views/IOS.vue'
import Legacy from '../views/Legacy.vue'
import AMX from '../views/AMX.vue'
import PreAC from '../views/PreAC.vue'
import NewsLetter from '../views/NewsLetter.vue'

/* eslint no-mixed-spaces-and-tabs: "off" */
const routes = [
    {
	path: '/',
	name: 'Home',
	component: Home
    },
    {
	path: '/android-compose-pre',
	name: 'Android Compose Pré',
	component: PreAC,
	meta: {
	    stylesheet: "black"
	}
    },
    {
	path: '/ae',
	name: 'Android Express',
	component: AE,
	meta: {
	    stylesheet: "green"
	}
    },
    {
	path: '/newsletter',
	name: 'NewsLetter',
	component: NewsLetter
    },
    {
	path: '/ios',
	name: 'iOS Developer',
	component: IOS
    },
    {
	path: '/legacy',
	name: 'iOS Developer Legacy',
	component: Legacy
    },
    {
	path: '/109AJsdaku',
	name: 'Bonecas Amiguxas',
	component: AMX,
	meta: {
	    stylesheet: "pink"
	}
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(MiddlewareStylesheet);

export default router
