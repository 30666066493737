<template>
  <div class="home">
    <!--
      <p>Working: {{ time }}</p>
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    el: '#app-2',
    data: function() {
        return {
            time: new Date()
        }
    }
  // name: 'Home',
  // components: {
  //   // HelloWorld
  // }
}
</script>

<style lang="css">
  /*@import '../assets/css/themes/theme-test.css';
  /* If using the npm package, use the following lines instead of the one above */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css'; */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css'; */
</style>
