<template>
    <Form :questionList="questions" :formName="formName" />
</template>

<script>
/* eslint no-mixed-spaces-and-tabs: "off" */
import Form from '@/components/Form.vue'
import { QuestionModel, QuestionType, ChoiceOption } from '@ditdot-dev/vue-flow-form';
export default {
    name: 'PreAC',
    components: {
	Form
    },
    data() {
	return {
	    formName: "android-compose-preform",
	    questions: [ 
		new QuestionModel({
		    id: 'name',
		    // tagline: 'Hi! Welcome to our demo survey 😊',
		    title: 'Olá, qual o seu nome?',
		    type: QuestionType.Text,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'goal_text',
		    title: 'Seja muito bem-vindo! Gostaria de te conhecer melhor. Qual o seu objetivo em aprender a criar aplicativos com compose?',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'choice_age_rate',
		    title: 'Em qual faixa de idade você se encontra?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    options: [
			new ChoiceOption({ label: 'Menos de 18 anos' }),
			new ChoiceOption({ label: 'Entre 18 e 24 anos' }),
			new ChoiceOption({ label: 'Entre 25 e 34 anos' }),
			new ChoiceOption({ label: 'Entre 35 e 44 anos' }),
			new ChoiceOption({ label: 'Entre 45 e 54 anos' }),
			new ChoiceOption({ label: 'Acima de 55 anos' }),
		    ]
		}),
		new QuestionModel({
		    id: 'choice_gender',
		    title: 'Qual seu gênero?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    allowOther: true,
		    options: [
			new ChoiceOption({ label: 'Masculino' }),
			new ChoiceOption({ label: 'Feminino' }),
		    ]
		}),
		new QuestionModel({
		    id: 'multiple_choice_source',
		    title: 'Certo!\nE você conheceu meu trabalho por onde?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    allowOther: true,
		    options: [
			new ChoiceOption({ label: 'Indicação' }),
			new ChoiceOption({ label: 'Instagram' }),
			new ChoiceOption({ label: 'Youtube' }),
			new ChoiceOption({ label: 'Blog' }),
			new ChoiceOption({ label: 'Facebook' }),
			new ChoiceOption({ label: 'Linkedin' }),
			new ChoiceOption({ label: 'Tiktok' }),
			new ChoiceOption({ label: 'Twitter / X' }),
		    ]
		}),
		new QuestionModel({
		    id: 'challenge',
		    title: 'Qual é o seu maior desafio atualmente?',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'level_text',
		    title: 'Maravilha!\nÚltima pergunta, como você considera o seu nível de programação?',
		    subtitle: 'Descreva um pouco até onde já aprendeu',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		})
	    ]
	}
    }
}
</script>
