<template>
    <Form :questionList="questions" :formName="formName" />
</template>

<script>
/* eslint no-mixed-spaces-and-tabs: "off" */
import Form from '@/components/Form.vue'
import { QuestionModel, QuestionType, ChoiceOption } from '@ditdot-dev/vue-flow-form';

export default {
    name: 'NewsLetter',
    components: {
	Form
    },
    data() {
	return {
	    formName: "newsletter",
            questions: [
		new QuestionModel({
		    id: 'name',
		    tagline: 'Seja bem-vindo(a) à minha Newsletter! 😊 Aproveite para responder nossa rápida pesquisa de apenas 58 segundos.\nEla é o melhor meio de entender o que você deseja consumir aqui na Newsletter',
		    title: 'Olá, qual o seu nome?',
		    subtitle: '',
		    type: QuestionType.Text,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		new QuestionModel({
		    id: 'choice_age_rate',
		    title: 'Em qual faixa de idade você se encontra?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    options: [
			new ChoiceOption({ label: 'Menos de 18 anos' }),
			new ChoiceOption({ label: 'Entre 18 e 24 anos' }),
			new ChoiceOption({ label: 'Entre 25 e 34 anos' }),
			new ChoiceOption({ label: 'Entre 35 e 44 anos' }),
			new ChoiceOption({ label: 'Entre 45 e 54 anos' }),
			new ChoiceOption({ label: 'Acima de 55 anos' }),
		    ]
		}),
		new QuestionModel({
		    id: 'choice_gender',
		    title: 'Qual seu gênero?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    allowOther: true,
		    options: [
			new ChoiceOption({ label: 'Masculino' }),
			new ChoiceOption({ label: 'Feminino' }),
		    ]
		}),
		new QuestionModel({
		    id: 'choice_source',
		    title: 'Por onde você me conheceu?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    allowOther: false,
		    options: [
			new ChoiceOption({ label: 'Indicação' }),
			new ChoiceOption({ label: 'Instagram' }),
			new ChoiceOption({ label: 'Youtube' }),
			new ChoiceOption({ label: 'Blog' }),
			new ChoiceOption({ label: 'Facebook' }),
			new ChoiceOption({ label: 'Linkedin' }),
			new ChoiceOption({ label: 'Tiktok' }),
			new ChoiceOption({ label: 'Twitter / X' }),
		    ]
		}),
		new QuestionModel({
		    id: 'choice_platform',
		    title: 'Qual plataforma você tem interesse?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    allowOther: false,
		    options: [
			new ChoiceOption({ label: 'Android / Kotlin' }),
			new ChoiceOption({ label: 'iOS / Swift' }),
		    ]
		}),
		new QuestionModel({
		    id: 'choice_content',
		    title: 'Qual conteúdo você deseja ler nessa Newsletter?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: true,
		    required: true,
		    allowOther: true,
		    options: [
			new ChoiceOption({ label: 'Linguagem de programação' }),
			new ChoiceOption({ label: 'Código limpo' }),
			new ChoiceOption({ label: 'Soft Skills' }),
			new ChoiceOption({ label: 'Freelancer' }),
			new ChoiceOption({ label: 'Negócios & Empreendedorismo' }),
			new ChoiceOption({ label: 'Arquitetura' }),
			new ChoiceOption({ label: 'Algoritmos / Estrutura de dados' }),
		    ]
		}),
		new QuestionModel({
		    id: 'choice_work',
		    title: 'Qual opção melhor descreve seu trabalho?',
		    helpTextShow: false,
		    type: QuestionType.MultipleChoice,
		    multiple: false,
		    required: true,
		    allowOther: true,
		    options: [
			new ChoiceOption({ label: 'Sou empregado' }),
			new ChoiceOption({ label: 'Sou freelancer' }),
			new ChoiceOption({ label: 'Sou estudante' }),
			new ChoiceOption({ label: 'Sou indie dev' }),
		    ]
		}),
		new QuestionModel({
		    id: 'challenge',
		    title: 'Qual é o seu maior desafio atualmente?',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
		
		new QuestionModel({
		    id: 'ask_me',
		    title: 'Tendo 1 hora de consultoria comigo, qual pergunta me faria?',
		    type: QuestionType.LongText,
		    required: true,
		    placeholder: 'Responder aqui...'
		}),
            ]
	}
    }
}
</script>
