<template>
   <flow-form
      ref="flowform"
      v-on:complete="onComplete"
      v-bind:questions="questions"
      v-bind:language="language"
      v-bind:standalone="true"
    >
     <template v-slot:complete>
        <div class="f-section-wrap">
            <div v-if="loading">
              <p>
                <span class="fh2">
                    Por favor aguarde. Enviando ...
                </span>
              </p>
            </div>
            <div v-else>
                <p v-if="failure">
                <span class="fh2">
                    Ops! Algo deu errado. Tente novamente mais tarde!
                </span>
              </p>
                <p v-else>
                <span class="fh2">
                    Muito obrigado 🙏 pelo seu tempo em responder!<br> Agora estamos juntos nessa nova jornada :)
                </span>
              </p>
            </div>
        </div>  
      </template>

      <template v-slot:completeButton>
          <div class="f-submit">
          </div>
      </template>
    </flow-form>
  

</template>

<script>
/* eslint no-mixed-spaces-and-tabs: "off" */
import { FlowForm, LanguageModel  } from '@ditdot-dev/vue-flow-form';

export default {
    name: 'Form',
    props: {
	formName: String,
	questionList: {
	    type: Array,
	    required: true
	}
    },
    components: {
	FlowForm
    },
    data() {
	return {
            loading: false,
            failure: false,
            submitted: false,
            completed: false,
            language: new LanguageModel({
		otherPrompt: 'Outro',
		pressEnter: 'Pressione :enterKey',
		longTextHelpText: ':shiftKey + :enterKey para fazer uma quebra de linha.',
		submitText: 'Enviar'
            }),
            questions: this.questionList
	}
    },

    mounted() {
	document.addEventListener('keyup', this.onKeyListener)
    },

    beforeUnmount() {
	document.removeEventListener('keyup', this.onKeyListener)
    },

    methods: {
	onKeyListener($event) {
            // We've overriden the default "complete" slot so
            // we need to implement the "keyup" listener manually.

            if ($event.key === 'Enter' && this.completed && !this.submitted) {
		this.onSendData()
            }
	},

	/* eslint-disable-next-line no-unused-vars */
	onComplete(completed, questionList) {
            // This method is called whenever the "completed" status is changed.

            this.$refs.flowform.submitted = true

            this.completed = completed

            this.onSendData()
	},

	onSendData() {
            // Set `submitted` to true so the form knows not to allow back/forward
            // navigation anymore.
            this.$refs.flowform.submitted = true
            this.loading = true

            this.submitted = true

            /* eslint-disable-next-line no-unused-vars */
            const self = this
            const data = this.getData()

            var result = {};
            data.questions.forEach((key, i) => result[key] = data.answers[i]);

            // You can use Fetch API to send the data to your server, eg.:
	    fetch(`${process.env.VUE_APP_ATWAY_BASE_URL}/survey/${this.formName}`, {
		method: 'POST',
		headers: {
		    'Content-Type': 'application/json',
		    'x-api-key': process.env.VUE_APP_ATWAY_API_KEY
		},
		body: JSON.stringify(result)
	    })
    	    .then(response=>response.json())
	    .then(data=>{ 
		console.log(data); 
		self.loading = false
	    })
	    .catch(function(error) {
		self.loading = false
		self.failure = true
		console.log('There has been a problem with your fetch operation: ' + error.message);
	    });
	},

	getData() {
            const data = {
		questions: [],
		answers: []
            }

            this.questions.forEach(question => {
		if (question.title) {
		    let answer = question.answer
		    if (Array.isArray(answer)) {
			answer = answer.join(', ')
		    }

		    data.questions.push(question.id)
		    data.answers.push(answer)
		}
            })

            return data
	}
    }

}
</script>
